.nav-tabs {
  border: none;
  margin: 0 auto;
}
.nav-tabs .nav-link {
  background-color: #eee !important;
  color: #2f78b7 !important;
  border: 1px solid #d0d8e3;
  /* border: 1px solid #ddd; */
  margin-right: 2px;
}
.nav-tabs .nav-link:hover {
  color: #6e9fe2 !important;
  border-top: 4px solid #c0d3ed;
  border-left: 1px solid #d0d8e3;
  border-right: 1px solid #d0d8e3;
}
.nav-tabs .active {
  border-bottom: 0 !important;
  border-top: 4px solid #2f78b7 !important;
  position: relative;
  color: #444444 !important;
  background: #fff !important;
  border-left: 1px solid #d0d8e3;
  border-right: 1px solid #d0d8e3;
}
.nav-tabs .active:hover {
  background: none !important;
  color: #6e9fe2 !important;
}
.tab-pane {
  /* margin-top: -1px !important; */
  background-color: #fff !important;
  border: 1px solid #ddd !important;
  min-width: inherit;
  margin-bottom: 40px;
}
.tab-content-padding {
  padding-top: 30px;
  padding-bottom: 30px;
}
.card-header-tabs {
  margin-right: 0rem !important;
  margin-bottom: 0rem !important;
  margin-left: 0rem !important;
  border-bottom: 0 !important;
}
.tab-content-data {
  margin: 0px !important;
  padding: 30px;
}