.plus-button-icon {
  color: var(--gg-blue) !important;
  margin-top: 8px !important;
}

.add-subGroup-button {
  color: var(--gg-blue) !important;
  font-size: 27px !important;
  width: 40px !important;
  margin-top: 7px !important;
}

.alert-heading {
  font-weight: 400;
  font-size: 1.3rem;
}

.rdtPicker {
  position: relative !important;
}

.addon-setting {
  position: fixed;
  margin-top: 150px;
}

.button-test {
  padding: 6px 16px !important;
  font-size: 0.875rem !important;
  min-width: 64px !important;
  box-sizing: border-box !important;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-weight: 500 !important;
  line-height: 1.75 !important;
  border-radius: 4px !important;
  letter-spacing: 0.02857em !important;
  text-transform: uppercase !important;
  color: inherit !important;
  border: 0 !important;
  cursor: pointer !important;
  margin: 0 !important;
  display: inline-flex !important;
  outline: 0 !important;
  padding: 0 !important;
  position: relative !important;
  align-items: center !important;
  user-select: none !important;
  border-radius: 4px !important;
  vertical-align: middle !important;
  -moz-appearance: none !important;
  justify-content: center !important;
  text-decoration: none !important;
  background-color: transparent !important;
  -webkit-appearance: none !important;
  -webkit-tap-highlight-color: transparent !important;
  height: 38px !important;
  color: white !important;
  background-color: var(--gg-blue) !important;
  margin: 10px 10px 0px 10px !important;
}
