.rotate {
    /* Safari */
    -webkit-transform: rotate(90deg);

    /* Firefox */
    -moz-transform: rotate(-90deg);

    /* IE */
    -ms-transform: rotate(90deg);

    /* Opera */
    -o-transform: rotate(90deg);

    /* Internet Explorer */
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}

.sidebar-contact {
    z-index: 80;
    position: fixed;
    top: 50%;
    right: -380px;
    transform: translateY(-50%);
    width: 380px;
    height: auto;
    padding: 40px;
    background: #fff;
    box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    transition: 0.5s;
}

.sequence-dashboard.active {
    right: 0;
}

.sequence-dashboard {
    z-index: 80;
    position: fixed;
    top: 50%;
    right: -380px;
    transform: translateY(-50%);
    width: 380px;
    height: auto;
    padding: 40px;
    background: #fff;
    box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    transition: 0.5s;
}

.sidebar-contact.active {
    right: 0;
}

/* .sidebar-contact input, */
/* .sidebar-contact textarea {
    width: 100%;
    height: 36px;
    padding: 5px;
    margin-bottom: 10px;
    box-sizing: border-box;
    border: 1px solid rgba(0, 0, 0, 0.5);
    outline: none;
  } */
/* .sidebar-contact h2 {
  } */
/* .sidebar-contact textarea {
    height: 60px;
    resize: none;
  } */
.type {
    padding-left: 15px !important;
}

.sidebar-contact input[type="submit"] {
    background: #00bcd4;
    color: #fff;
    cursor: pointer;
    border: none;
    font-size: 18px;
}

.feedbacktoggle {
    position: absolute;
    height: 50px;
    width: 100px;
    text-align: center;
    cursor: pointer;
    background: #337ab7;
    left: -75px;
    line-height: 48px;
}

.feedbacktoggle:before {
    font-family: fontAwesome;
    font-size: 16px;
    color: #fff;
}

.feedbacktoggle.active:before {
    font-family: fontAwesome;
    font-size: 20px;
    content: "";
    left: 70px;
}

.feedbacktoggle.active span {
    display: none;
}

.sidebar-contact .feedbacktoggle {
    top: 4.5%;
    /* transform:translateY(-50%); */
    transition: 0.5s;
}

.sequence-dashboard .feedbacktoggle {
    top: 7.5%;
    transition: 0.5s;
}

.sidebar-contact.active {
    z-index: 100 !important;
}

.sequence-dashboard.active {
    z-index: 100 !important;
}

/* @media (min-width: 768px) and (max-width: 991px) {
    .sequence-dashboard .toggle {
    top: 2%;
    transition: 0.5s;
  }
  } */
@media (max-width: 767px) {
    .sidebar-contact {
        width: 100%;
        height: 100%;
        right: -100%;
    }

    .sequence-dashboard {
        width: 100%;
        height: 100%;
        right: -100%;
    }

        .sidebar-contact .feedbacktoggle {
        top: 15.5%;
        transition: 0.5s;
    }

        .sequence-dashboard .feedbacktoggle {
        top: 30.0%;
        transition: 0.5s;
    }

        .sequence-dashboard.active .feedbacktoggle {
        top: 8.5%;
        right: 0;
        left: 85.5% !important;
    }

        .sidebar-contact.active .feedbacktoggle {
        top: 8.5%;
        right: 0;
        left: 85.5% !important;
        /* transform: translatex(0); */
    }

    .scroll {
        width: 100%;
        height: 100%;
        overflow-y: auto;
    }

    .content {
        padding: 50px 50px;
    }
}

@media (max-width: 480px) {
    .sidebar-contact.active .feedbacktoggle {
        top: 5%;
        left: 80% !important;
    }

        .sidebar-contact .feedbacktoggle {
        top: 15%;
        transition: 0.5s;
    }

        .sequence-dashboard .feedbacktoggle {
        top: 30%;
        /* transition: 0.5s; */
    }

        .sequence-dashboard.active .feedbacktoggle {
        top: 7%;
        left: 81% !important;
        /* transition: 0.5s; */
    }
}

.feedback_ {
    background: #fff;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    margin: 50px auto;
    -moz-box-shadow: 0 0 3px #555;
    -webkit-box-shadow: 0 0 3px #555;
    box-shadow: 0 0 3px #555;
    padding: 15px;
}

.feedback_ h5 {
    color: #336699 !important;
    line-height: 1;
    margin-bottom: 10px;
}

.feedback_ p {
    margin-bottom: 5px;
}

.feedback_ div.feedbbacktype span {
    display: inline-block;
    margin-right: 5px;
}

.feedback_ div.feedbbacktype span:last-child {
    margin-right: 0;
}

.feedback_ div.feedbbacktype span.active span {
    background: #336699;
    color: white;
    position: relative;
}

.feedback_ div.feedbbacktype span.active span:after {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: "  ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
    border-top-color: #336699;
    border-width: 5px;
    margin-left: -5px;
}

.feedback_ div.feedbbacktype span {
    background: #f1f1f1;
    padding: 5px 10px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    font-size: 0.8em;
    color: #555;
}

.feedback_ .feedbbacktype span:hover {
    background: #336699;
    color: white;
    cursor: pointer;
}

.feedback_ div.feedbbacktype span i {
    margin-right: 2px;
}

.feedback_ div.feedbbacktype.speed col:first-child span:hover {
    background: #57a854;
    color: white;
}

.feedback_ div.feedbbacktype.speed col:nth-child(2) span:hover {
    background: #208cb6;
    color: white;
}

.feedback_ div.feedbbacktype.speed col:last-child span:hover {
    background: #f65b4f;
    color: white;
}

.feedback_ .form {
    margin-top: 10px;
}

.feedback_ .form textarea {
    min-height: 150px;
    resize: none;
}

.feedback_ .form input,
.feedback .form textarea {
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid #e6e6e6;
}

.feedback_ button.success {
    padding: 10px 15px;
    background: #337ab7;
    margin-top: 10px;
    margin-left: 200px;
    color: white;
}

.name {
    color: white;
}

.protvistaicon {
    position: fixed;
    top: 50%;
}

ol {
    color: #fffff8;
}

.feedbbacktype {
    padding: 0px;
    margin: 5px 0 15px 0;
}

.feedback_ div.feedbbacktype span.active {
    background: #336699;
    color: white;
    position: relative;
}