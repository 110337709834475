.statcard-icon-col {
  text-align: center;
  margin: 10% 7% 1%;
  /* width: 25%; */
}

.statcard-icon {
  color: rgb(47, 120, 180);
  font-size: 1.5em;
}
.statistics-icon {
  width: 30px;
  height: 30px;
}
.stat-label {
  color: #444444 !important;
  font-size: 16px;
  cursor: pointer;
  letter-spacing: 1px;
  margin: 24px 0px 14px;
  text-decoration: none !important;
}

.h2-css {
  font-family: Open Sans, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 21px;
  margin: 14px 0px 0px;
  text-transform: none;
}
