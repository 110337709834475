/* li:hover {
  text-decoration: underline;
  color: #1c4e78;
} */

.summar-links {
  color: var(--gg-blue);
  text-decoration: none;
  background-color: transparent;
  width: fit-content;
  cursor: pointer;
}
