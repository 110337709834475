.descriptor-header {
  text-align: left;
  color: green;
  margin-bottom: 0.5rem;
  font-size: 20px;
  border-bottom: 0.5rem;
  padding-top: 4px;
  font-size: 25px;
}

.font-awesome-color {
  color: green;
}

.fa-arrow {
  font-size: 40px;
  float: right;
  margin-top: -11px;
}

.delete-icon {
  color: red;
  margin-bottom: 8px;
}

/* .accordion > .card:first-of-type {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
} */

#dateField {
  background-color: white !important;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

.xorGroupHeader {
  font-size: larger;
  padding-right: 1.5em;
  font-weight: bold;
}

.xorHeaderBox {
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 1% !important;
  width: 98%;
  border-style: solid;
  border-width: thin;
  border-radius: 2px;
  border-color: lightgrey;
}
