.blocklayout-display {
  color: var(--gg-blue);
  font-size: larger;
  font-weight: 300;
  cursor: pointer;
}

.slideHeader {
  margin-left: 10px;
}

.slideTableofContents {
  color: steelblue;
  font-size: large;
  cursor: pointer;
}
