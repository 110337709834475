/* .structure-image {
  max-width: 150px;
} */

/* .structure-image:not(.img-not-found-error):hover {
  -webkit-transform: scale(2);
  transform: scale(2);    
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
} */

/*img.structure-image {
  cursor: -moz-zoom-in;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}*/
.image-container {
  overflow: hidden;
  z-index: -1000;
}
.structure-image {
  width: auto;
  height: auto;
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  box-shadow: 10px 10px 5px #ddd;
}

.structure-image:hover,
.structure-image:focus,
.structure-image:not(.img-not-found-error):hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  z-index: 1000;
  box-shadow: none !important;
}