.formButton {
  height: 38px;
}

.stepper-button {
  height: 38px !important;
  color: white !important;
  background-color: var(--gg-blue) !important;
  margin: 10px 10px 0px 10px !important;
}

.stepper-button:disabled {
  color: rgba(0, 0, 0, 0.5) !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
}

.button-div {
  padding-right: 0% !important;
}

/*MultiToggle*/

.toggle-wrapper label {
  padding: 0.3em;
  font-size: 17px;
  display: block;
}

.toggleContainer {
  width: 100%;
  position: relative;
  background: #ffffff;
  border: 1px solid #28a745;
  border-radius: 0.25rem;
  height: 38px;
}

.toggleContainer .toggleOption {
  display: inline-block;
  position: relative;
  z-index: 1;
  text-align: center;
  height: 38px;
  line-height: 38px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  color: #807b77;
  font-size: 0.8em;
}

.toggleContainer .toggleOption.selected {
  color: #ffffff;
  cursor: initial;
}

.toggleContainer .toggle {
  position: absolute;
  height: 101%;
  bottom: 0;
  left: 0;
  transition: all 0.4s ease-in-out;
  background: var(--gg-blue);
}

.optionDisabled {
  background: #e6e6e6;
  cursor: no-drop !important;
}

/*End MultiToggle*/

.sequence-text-area {
  font-size: 0.95em;
  font-family: monospace;
}
