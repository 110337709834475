.side-menu-header {
  padding: 15px 0;
  color: #13548d;
  font-size: 1.8em;
  font-weight: 600;
  border-bottom: 1px solid #acabab;
  text-align: center;
}

 /* .MuiListItem-button:hover,  */
.active-sidebar
{
  background-color: var(--gg-blue) !important;
  color: #fff !important;
}
.sidebar-item-text {
  font-weight: 600 !important;
  text-decoration: none !important;
}

.sidebar-ittem:hover
{
  background-color: #fff !important;
  color: var(--gg-blue) !important;
  -webkit-transition: all 0.5s !important;
  transition: ease-in-out 0.5s !important;
}

.MuiTypography-body1 {
  /* font-size: 1.1em !important; */
  font-weight: 600!important;
}
