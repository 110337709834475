.rst__rowWrapper {
  padding: 6px 6px 10px 0;
  height: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.rst__row {
  height: 100%;
  white-space: nowrap;
  display: -ms-flexbox;
  display: flex;
}

.row_headline {
  height: 45px;
  width: 100%;
  border: solid #aaa 1px;
  -webkit-box-shadow: 0 2px 2px -2px;
  box-shadow: 0 2px 2px -2px;
  /* cursor: default; */
  border-radius: 1px;
  display: flex;
  align-items: center;
  margin-left: 1px !important;
  overflow: hidden;
  /* justify-content: space-evenly; */
}

.btn-more-less {
  border: none;
  background-color: white;
}

.row_headline:not(.disable):hover {
  background-color: #e9ecef;
  cursor: pointer;
}

.row_headline_act {
  background-color: #e9ecef;
}