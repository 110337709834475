.sidenav {
  height: 100%;
  position: sticky !important;
  z-index: 1;
  top: 0;
    /* left: 0; */
  background-color: #e6e6e6;
  overflow-x: hidden;
  transition: 0.5s;
  /* padding-top: 10px; */
    /* margin-top: 72px; */
    /* margin-bottom: 50px !important; */
  text-align: center;
  /* min-width: 250px !important; */
    /* padding-bottom: 150px; */
    overflow-y: auto;
    display: flex !important;
}

.sidenav a {
  color: #4a4a4a;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.page-container {
  margin-bottom: 40px;
  padding-left: 5%;
  padding-right: 5%;
  /* width: 90%; */
}
/* .MuiList-padding {
  padding-bottom: 80px !important;
} */

/* .page-description {
  width: 80%;
  padding-left: 20%;
  text-align: justify;
} */
@media (min-width: 280px) and (max-width: 767px)  {
  .page-container {
    /* padding-left: 30% !important; */
      /* margin-bottom: 40px; */
    /* width: 0%; */
  }
}
@media screen and (min-width: 600px) {
  .page-container {
    /* padding-left: 30% !important; */
    /* width: 0%; */
  }
}
@media screen and (min-width: 768px) {
  .page-container {
    /* padding-left: 30% !important; */
      /* margin-bottom: 40px; */
    /* width: 100%; */
  }
}
@media screen and (min-width: 992px) {
  .page-container {
    /* padding-left: 30% !important; */
      /* margin-bottom: 40px; */
    /* width: 90%; */
  }
}
@media screen and (min-width: 1024px) {
  .page-container {
    /* padding-left: 25% !important; */
      /* margin-bottom: 40px; */
    /* width: 90%; */
  }
}
@media screen and (min-width: 1200px) {
  .page-container {
    /* padding-left: 20% !important; */
      /* width: 95%; */
      /* margin-bottom: 40px; */
  }
}
