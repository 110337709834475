.table-btn {
  width: 25px;
  height: 25px;
  margin-right: 5px;
  cursor: pointer;
  /*font-size: large !important; */
}

/* .table-btn:hover {
  opacity: 0.6;
  border: 1px solid black;
} */

.ReactTable .-pagination .-btn {
  background-color: var(--gg-blue) !important;
  color: white !important;
}

.ReactTable .-pagination .-btn:enabled:hover {
  opacity: 0.8;
}

.ReactTable .-loading.-active {
  margin-top: 6.2%;
  margin-bottom: 4.2%;
}

.image-icon {
  margin-bottom: 10px;
}

.caution-color {
  color: red;
}
.warning-color {
  color: #eed202;
}

.success-color {
  color: green;
}
.tbl-icon-btn {
  margin-right: 10px;
  margin-left: 10px;
  cursor: pointer;
}
/* .edit-icon {
  color: var(--gg-blue);
} */
.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
  background-color: #e2e8ef !important;
}
.ReactTable {
  background-color: #fff !important;
}
.table-rows-info {
  padding: 15px 15px 15px 5px;
}
.ReactTable .rt-tbody .rt-td {
  border-right: 1px solid #ced2d6 !important;
}
.ReactTable .rt-th,
.ReactTable .rt-td {
  padding: 10px 15px;
}

/* Table Sortiing */
.MyReactTableClass .-sort-desc,
.MyReactTableClass .-sort-asc {
  box-shadow: none !important;
  background-color: transparent !important;
}

.MyReactTableClass .-sort-desc::before {
  position: absolute;
  right: 15px;
  top: 35%;
  content: "▼";
  float: right;
  color: #fff;
}

.MyReactTableClass .-sort-asc::before {
  position: absolute;
  right: 15px;
  top: 35%;
  content: "▲";
  float: right;
  color: #fff;
}

/* .fa-disabled {
  opacity: 0.6;
  cursor: not-allowed;
} */

.fa-disabled,
.fa[disabled],
.disabled > .fa,
[disabled] > .fa {
  opacity: 0.5;
  /*optional*/
  cursor: not-allowed;
  /*optional*/
  pointer-events: none;
}
